import React from 'react';
import styled, { css } from 'styled-components';
import { formatDate } from '../helpers';

const borderColor = '#aaa'

const memberHeight = 3
const leftIndentEM = 3
const topIndentEM = 0.5

const connectionCss = css`
  height: ${({ connectionHeight }) => connectionHeight ? `${connectionHeight}px` : `${(1.5 + topIndentEM + memberHeight / 2)}em`};
  top: ${({ connectionHeight }) => connectionHeight ? `calc(-${connectionHeight}px + ${memberHeight / 2}em)` : `-${topIndentEM + 0.05 + memberHeight / 2}em`};
`

const DetailsStyled = styled.details`
  white-space: nowrap;
  ${props => props.freeze && "overflow: hidden;"}
  &::before { ${connectionCss} }
  > summary {
    line-height: 100%;
    align-items: center;
    display: block;
  }
  summary::-webkit-details-marker {
    color: ${borderColor};
    margin-top: 0.65em;
    vertical-align: middle;
  }
  > summary::marker {
    margin-top: 0.65em;
    vertical-align: middle;
    color: ${borderColor};
  }
 @-moz-document url-prefix() {
    > summary {
    line-height: 100%;
    display: list-item !important;
    }
  }

  > section {
    margin-left: ${leftIndentEM}em;

    &::before {
      content: "oo";
      line-height: 3.9em;
      letter-spacing: -0.3em;
      text-indent: 0.15em;
      z-index: 10;
      width: ${leftIndentEM - 1.5}em;
      height: ${1.5 + topIndentEM}em;
      border-left: 1px solid ${borderColor};
      border-bottom: 1px solid ${borderColor};
      flex-shrink: 0;
      position: absolute;
      top: -${topIndentEM + 0.05}em;
      left: -${leftIndentEM - 1.45}em;
    }
  }
}
`

const MembreStyled = styled.div`
  height: ${memberHeight}em;
  display: inline-flex !important;
  width: 320px;
  max-width: 70vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${borderColor};
  border-radius: 4px;
  padding: .5em .5em .25em;
  outline: none;
  margin-bottom: ${topIndentEM}em;
  cursor: pointer;
  &::before { ${connectionCss} }
`

const Arrow = styled.div`
    height: 25px;
    font-size: 2rem;
    cursor: pointer;
    line-height: 1em;
    /* margin-top: 30%; */
    display: inline-block;
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
`

const ButtonInfos = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid ${borderColor};
  color: ${borderColor};
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
`

const Enfants = styled.section`
  display: flex;
  flex-direction: column;

  > ${MembreStyled}::before, > ${DetailsStyled}::before {
      content: "";
      width: ${leftIndentEM - 2}em;
      border-left: 1px solid ${borderColor};
      border-bottom: 1px solid ${borderColor};
      flex-shrink: 0;
      position: absolute;
      left: -${leftIndentEM - 1.95}em;
    }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ID = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  max-width: calc(70vw - 2em - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

`

const Dates = styled.span`
  font-size: 0.75em;
`

const getDate = (mortOuNaissance, mort, debug = false) => {
  if (!mortOuNaissance || !mortOuNaissance.date) return mort ? '' : 'Date de naissance inconnue'
  return mort ? ' - ' + formatDate(mortOuNaissance.date) : formatDate(mortOuNaissance.date);
}

const Membre = ({ onClick, connectionHeight, onAscendantClick, onPlusDInfosClick, isRoot, ...membre }) =>
  <MembreStyled
    connectionHeight={connectionHeight}
    isRoot={isRoot}
    onClick={(e) => {
      e.stopPropagation()
      onClick(membre.id)
    }}
  >
    <Content>
      <ID>
        <span>{membre.prenom}&nbsp;{membre.nom ? membre.nom.toUpperCase() : ''}</span>
        <Dates>{getDate(membre.naissance, false, true)}{getDate(membre.mort, true)}</Dates>
      </ID>
      <ButtonInfos
        onClick={(e) => {
          e.stopPropagation();
          onPlusDInfosClick(membre.id)
        }}
      >i</ButtonInfos>
    </Content>
    {isRoot && membre.familles && membre.familles.ascendante && membre.familles.ascendante.length && <Arrow
      dangerouslySetInnerHTML={{__html: '&#x2191' }}
      onClick={(e) => {
        e.stopPropagation()
        onAscendantClick(membre.id)
      }}/>}
  </MembreStyled>


class Famille extends React.Component {
  state = { childrenToggled: {} }

  handleChildToggling = ind => height => {
    this.setState(({ childrenToggled }) => ({ childrenToggled: {
      ...childrenToggled,
      [ind]: height
    } }));

  }

  render() {
    const { famille, tellSiblingsHeight, connectionHeight, isRoot, onAscendantClick, onClick, onPlusDInfosClick, freeze } = this.props;

    const root = () => (
      <Membre
        {...famille}
        onAscendantClick={onAscendantClick}
        onPlusDInfosClick={onPlusDInfosClick}
        onClick={onClick}
        isRoot={isRoot}
        connectionHeight={connectionHeight}
      />
    )

    if (!famille.mariages || !famille.mariages.length) {
      return root()
    }

    return(
        <DetailsStyled
          ref={r => this.details = r}
          onToggle={() => tellSiblingsHeight(this.details.getBoundingClientRect().height)}
          connectionHeight={connectionHeight}
          open={isRoot}
          freeze={freeze}
        >
          <summary>
            {root()}
          </summary>
          <section>
            {famille.mariages.map(mariage =>
              <React.Fragment key={mariage.id || 'PAS DE MARIAGE'}>
                <Membre
                  {...mariage}
                  isRoot={isRoot}
                  onAscendantClick={onAscendantClick}
                  onPlusDInfosClick={onPlusDInfosClick}
                  onClick={onClick}
                />
                {mariage.descendance && mariage.descendance.length &&
                  <Enfants>
                  {mariage.descendance.map((enfant, ind) => {
                    return <Famille
                      key={enfant.id}
                      famille={enfant}
                      tellSiblingsHeight={this.handleChildToggling(ind)}
                      connectionHeight={this.state.childrenToggled[ind-1]}
                      name={enfant.prenom}
                      onClick={onClick}
                      onPlusDInfosClick={onPlusDInfosClick}
                    />
                  })}
                </Enfants>}
              </React.Fragment>
            )}
          </section>
        </DetailsStyled>

    )
  }
}

Famille.defaultProps = {
  tellSiblingsHeight: () => null,
  isRoot: false
}

export default Famille;
