import React from 'react';
import styled from 'styled-components'
import { findIndividual, formatDateForInput } from '../helpers';
import Modale from './modale';
import { media } from '../styles/mediaQueries';

const borderColor = '#aaa'

const DualInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    flex: 1;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: 150px;
  }
  ${media.mobile`
    flex-direction: column;
    > div {
      flex: 0;
      flex-shrink: 0;
      flex-wrap: nowrap;
      flex-basis: auto;
    }
  `}
`

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 40px;
  width: 100%;
  margin-bottom: 15px;
`

const LabelStyled = styled.label`
  flex-basis: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
  font-weight: bold;
`

const InputStyled = styled.textarea`
  border: none;
  resize: none;
  height: 100%;
  flex-grow: 1;
  border-bottom: 1px solid ${borderColor};
  &:disabled {
    border-bottom: none;
    opacity: 1;
    color: black;
  }
`

const ButtonEdit = styled.a`
  height: 40px;
  font-weight: 500;
  border: 1px solid ${borderColor};
  width: 250px;
  margin: 0;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 1.45rem 1.0875rem;
  cursor: pointer;
  text-align: center;
  max-width: 100%;
`

const Input = ({ name, value, onChange, label, type = "text", ...props }) => (
  <InputContainer>
    <LabelStyled htmlFor={name}>{label}</LabelStyled>
    <InputStyled
      disabled
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      form="infos"
      {...props}
    />
  </InputContainer>
)

const Infos = ({ individualCode, individuals, onMoinsDInfosClick }) => {

  const membre = findIndividual(individuals, individualCode);
  const [infos, setInfos] = React.useState(membre)

  const updateMembre = ({ target: { value, name } }) => {
    setInfos({...infos, [name]: value })
  }

  return(
    <Modale onCloseClick={onMoinsDInfosClick}>
        <Input
          name="nom"
          value={infos.nom}
          label="Nom"
          onChange={updateMembre}
        />
        <Input
          name="prenoms"
          value={infos.prenoms}
          label="Prénoms"
          onChange={updateMembre}
        />
        <Input
          name="sexe"
          value={infos.sexe}
          label="Sexe"
          onChange={updateMembre}
        />
        <DualInput>
          <Input
            name="naissance-date"
            value={formatDateForInput(infos.naissance.date, true)}
            label="Naissance"
            onChange={updateMembre}
            type="date"
          />
          <Input
            name="naissance-place"
            value={infos.naissance.place}
            label="À"
            onChange={updateMembre}
          />
        </DualInput>
        {infos.mort && infos.mort.date && (
          <DualInput>
          <Input
            name="mort-date"
            value={formatDateForInput(infos.mort.date)}
            label="Mort"
            onChange={updateMembre}
            type="date"
          />
          <Input
            name="mort-place"
            value={infos.mort.place}
            label="À"
            onChange={updateMembre}
          />
        </DualInput>
        )}
        {infos.occupation && <Input
          name="occupation"
          value={infos.occupation}
          label="Occupation"
          onChange={updateMembre}
          style={{
            textTransform: 'capitalize'
          }}
        />}
        {infos.notes && <Input
          name="occupation"
          value={infos.notes}
          label="Notes"
          onChange={updateMembre}
          as="textarea"
          style={{ minHeight: 200 }}
        />}
        <ButtonEdit
          href={`mailto:jmambroselli@icloud.com?subject=Modification%20de%20${infos.recherche.join(' ')}%20-%20id%20${infos.id}&body=Veuillez%20préciser%20vos%20coordonnées%20pour%20pouvoir%20vous%20répondre,%20merci%20!`}
        >
          Proposer une modification
        </ButtonEdit>
      </Modale>
  )
}

export default Infos;
