import React from 'react';
import styled from 'styled-components'
import Recherche from './recherche';

const AccueilContainer = styled.div`
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* box-shadow: 0 0 25px 11px rgba(0,0,0,0.1);
  border-radius: 20px; */
  padding: 1.45rem 1.0875rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > span {
    font-weight: 500;
  }
`

class Accueil extends React.Component {

  render() {
    const { individuals, onClick } = this.props;
    return(
      <AccueilContainer>
        <span>Bienvenue dans l'arbre généalogique des familles Missoffe&nbsp;!</span>
        <Recherche individuals={individuals} onClick={onClick}/>
      </AccueilContainer>
     )
  }
}

export default Accueil;
