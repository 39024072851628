function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

function isYear(unformattedDate) {
  if (unformattedDate.split(' ').length !== 1) return false;
  if (unformattedDate.split(' ')[0].length === 4) return true;
  return false;
}

export const formatDate = (date, debug = false) => {
  if (isYear(date)) return date;
  if (!isValidDate(new Date(date))) return 'Date inconnue';
  return (new Date(date + ' 12:00')).toLocaleDateString('fr', { day: 'numeric', month: 'short', year: 'numeric' })
}

const twoDigits = number => number < 10 ? `0${number}` : number;

export const formatDateForInput = (date, debug = false) => {
  const formattedDate = new Date(date);
  debug && console.log('formatDateForInput', formattedDate, formattedDate.getMonth)
  return `${formattedDate.getFullYear()}-${twoDigits(formattedDate.getMonth() + 1)}-${twoDigits(formattedDate.getDate())}`
}


const findAndReplace = (array, defaultReplacement, regex1, regex2) => {
  if (regex2) {
    const foundRegex1 = array.find(content => regex1.test(content))
    if (!foundRegex1) return defaultReplacement;
    const foundRegex2 = foundRegex1.split('\n2 ').find(content => regex2.test(content))
    return foundRegex2
      ? foundRegex2.split('\n2')[0].split('\n3')[0].replace(regex2, '')
      : defaultReplacement
  }
  return array.find(content => regex1.test(content))
  ? array.find(content => regex1.test(content)).replace(regex1, '')
  : defaultReplacement
}

const listAndReplace = (array, defaultReplacement, regex1, regex2) => {
  const foundRegex1 = array.filter(content => regex1.test(content))
  if (!foundRegex1 || !foundRegex1.length) return defaultReplacement;
  return foundRegex1.map(fam => fam.replace(regex1, ''));
}


const filterByRegex = (regex, withOrWithout = true, debug = false) => (string) => {
  debug && console.log(regex, string, regex.test(string))
  const stringIsRegex = regex.test(string);
  return withOrWithout ? stringIsRegex : !stringIsRegex
}


const ROOT = {
  INDI: new RegExp('@[0-9]{2,}@ INDI'),
  FAMILLE: new RegExp('@[0-9]{2,}@ FAM'),
}

const NAME = new RegExp('NAME ');
const PRENOM = new RegExp('GIVN ');
const NOM = new RegExp('SURN ');
const SEXE = new RegExp('SEX ');
const NAISSANCE = new RegExp('BIRT');
const FAMILLE_CODE_DESCENDANTE = new RegExp('FAMS ');
const FAMILLE_CODE_ASCENDANTE = new RegExp('FAMC ');
const MORT = new RegExp('DEAT');
const DATE = new RegExp('DATE ');
const PLACE = new RegExp('PLAC ');
const MARI = new RegExp('HUSB ');
const FEMME = new RegExp('WIFE ');
const ENFANT = new RegExp('CHIL ');
const CHANGE = new RegExp('CHAN');
const OCCUPATION = new RegExp('OCCU');
const NOTES = new RegExp('NOTE');
const NOTES_CONCAT = new RegExp('2 CONC ');


export const formatIndividual = pers => ({
  id: pers.find(content => ROOT.INDI.test(content)).replace(' INDI', ''),
  prenom: findAndReplace(pers, 'PRENOM INCONNU', NAME, PRENOM).split(' ')[0],
  prenoms: findAndReplace(pers, 'PRENOM INCONNU', NAME, PRENOM),
  nom: findAndReplace(pers, 'NOM INCONNU', NAME, NOM),
  sexe: findAndReplace(pers, 'SEXE INCONNU', SEXE),
  occupation: findAndReplace(pers, '', OCCUPATION),
  notes: findAndReplace(pers, '', NOTES)
          .split('2 CONT ')
          .map(note => note && NOTES_CONCAT.test(note)
                        ? note
                          .replace(/(\r\n|\n|\r)/gm,'')
                          .split('2 CONC ')
                          .join('')
                        : note
          ).join(''),
  naissance: {
    date: findAndReplace(pers, 'DATE DE NAISSANCE INCONNUE', NAISSANCE, DATE).split('3 TIME')[0],
    place: findAndReplace(pers, 'LIEU INCONNU', NAISSANCE, PLACE).split('3 TIME')[0]
  },
  mort: {
    date: findAndReplace(pers, '', MORT, DATE).split('3 TIME')[0],
    place: findAndReplace(pers, '', MORT, PLACE).split('3 TIME')[0]
  },
  recherche: [...findAndReplace(pers, 'PRENOM INCONNU', NAME, PRENOM).split(' '), ...findAndReplace(pers, 'NOM INCONNU', NAME, NOM).split(' ')],
  familles: {
    descendantes: listAndReplace(pers, [], FAMILLE_CODE_DESCENDANTE),
    ascendante: findAndReplace(pers, '', FAMILLE_CODE_ASCENDANTE),
  },
  rest: pers.filter(filterByRegex(ROOT.INDI, false)).filter(filterByRegex(CHANGE, false))
})

export const formatIndividualRoot = pers => ({
  ...formatIndividual(pers),
})

export const formatIndividualMariage = pers => ({
  ...formatIndividual(pers)
})

export const formatFamille = pers => ({
  id: pers.find(content => ROOT.FAMILLE.test(content)).replace(' FAM', ''),
  rest: pers.filter(filterByRegex(ROOT.FAMILLE, false))
})

export const formatIndividuals = (GEDData, mariage = false) =>
  GEDData
  .split('\n0 ')
  .map(data => data.split('\n1 '))
  .filter(pers => pers.find(content => ROOT.INDI.test(content)))
  .map(mariage ? formatIndividualMariage : formatIndividualRoot)

export const formatFamilles = GEDData => {
  return GEDData
  .split('\n0 ')
  .map(data => data.split('\n1 '))
  .filter(pers => pers.find(content => ROOT.FAMILLE.test(content)))
  .map(formatFamille)
}

export const findIndividual = (individuals, INDI_CODE) => individuals.find(pers => pers.id === INDI_CODE)

export const findFatherId = (GEDData, INDI_CODE) => {
  const familles = formatFamilles(GEDData);
  const individuals = formatIndividuals(GEDData);
  const famille = findIndividual(familles, findIndividual(individuals, INDI_CODE).familles.ascendante)
  return findAndReplace(famille.rest, 'PAS DE MARI', MARI)

}

export const createFamille = (familles, individuals, plusOnes, INDI_CODE) => {

  const root = findIndividual(individuals, INDI_CODE);

  if (root.familles.descendantes.length > 0) {
    return {
      ...root,
      // familles: undefined,
      mariages: root.familles.descendantes.map(familleId =>
        familles
        .filter(({ id }) => id === familleId)
        .map(famille => {
          const mari = findAndReplace(famille.rest, 'PAS DE MARI', MARI)
          const femme = findAndReplace(famille.rest, 'PAS DE MARI', FEMME)
          const plusOne = INDI_CODE === mari ? femme : mari;
          return {
            ...findIndividual(plusOnes, plusOne),
            descendance: listAndReplace(famille.rest, [], ENFANT).length
            ? listAndReplace(famille.rest, [], ENFANT)
              .map(INDI_CODE_ENFANT => createFamille(familles, individuals, plusOnes, INDI_CODE_ENFANT))
            : undefined
          };
        })[0]
      )
    }
  }
  return {
    ...root,
    familles: {
      ...root.familles,
      descendantes: undefined,
    }
  }
}

export const formatArbre = (GEDData, INDI_CODE) => {
  const familles = formatFamilles(GEDData);
  const individuals = formatIndividuals(GEDData);
  const plusOnes = formatIndividuals(GEDData, true);

  return createFamille(familles, individuals, plusOnes, INDI_CODE)
}
