import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

export const useWindowEvent = (event, callback) => {
  React.useEffect(() => {
    if (!window) return null;
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const getImageSrcFromImages = ({ node: { fluid: { src }}}) => src;

export const useImages = () => {
  const {
    allImageSharp:{edges: images },
  } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
    return images;
}
