import React from 'react';
import styled from 'styled-components'
import Modale from './modale';
import { useImages, getImageSrcFromImages } from '../helpers/hooks';

const Img = styled.div`
  background-image: url(${props => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`

const Aide = ({ onCloseAideClick }) => {

  const images = useImages();

  const aide = images.map(getImageSrcFromImages).map(img => {console.log('useImage', img);return img}).find(src => src.includes('aide.png'));

  return(
    <Modale onCloseClick={onCloseAideClick} fullScreen>
      <Img background={aide}/>
    </Modale>
  )
}

export default Aide;
