import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Famille from "../components/famille";

import data2 from '../data/JMA-HEREDIS-Export'
import { findFatherId, formatArbre, formatIndividuals } from "../helpers";
import Accueil from "../components/accueil";
import Infos from "../components/infos";
import Aide from "../components/aide";

const initRootCode = "@56@"

class IndexPage extends React.Component {
  state = {
    rootCode: initRootCode,
    infoCode: '',
    view: 'Accueil',
    arbre: formatArbre(data2, initRootCode),
    individuals: formatIndividuals(data2)
  }

  onAscendantClick = id => {
    const rootCode = findFatherId(data2, id);
    const arbre = formatArbre(data2, rootCode);
    this.setState({ rootCode, arbre, infoCode: '' })
  }

  onCloseAideClick = () => {
    this.setState({ view: this.prevView })
  }
  onClick = rootCode => {
    this.setState({ rootCode, arbre: formatArbre(data2, rootCode), view: 'Arbre', infoCode: '' })
  }

  onPlusDInfosClick = infoCode => this.setState({ infoCode })
  onMoinsDInfosClick = () => {
    this.setState({ infoCode: '' })
  }
  handleToolbarClick = view => {
    this.prevView = this.state.view;
    this.setState({ view, infoCode: '' })
  }

  renderFamille = () => {
    const { view, arbre, rootCode, infoCode } = this.state;
    if (view !== 'Arbre') return null;
    return(
      <Famille
         key={rootCode}
         isRoot
         famille={arbre}
         onAscendantClick={this.onAscendantClick}
         onPlusDInfosClick={this.onPlusDInfosClick}
         onClick={this.onClick}
         freeze={infoCode.length}
      />
    )
  }

  renderAccueil = () => {
    const { view, individuals } = this.state;
    if (view !== 'Accueil') return null;
    return(
      <Accueil
         individuals={individuals}
         onAscendantClick={this.onAscendantClick}
         onClick={this.onClick}
      />
    )
  }

  renderInfos = () => {
    const { infoCode, individuals } = this.state;
    if (!infoCode) return null;
    return(
      <Infos
         individuals={individuals}
         onMoinsDInfosClick={this.onMoinsDInfosClick}
         individualCode={infoCode}
      />
    )
  }

  renderAide = () => {
    if (this.state.view !== 'Aide') return null;
    return(
      <Aide
      onCloseAideClick={this.onCloseAideClick}
      />
    )
  }

  render() {
    const { view, infoCode } = this.state;
    return(
    <Layout
      withToolbar
      onToolbarClick={this.handleToolbarClick}
      freeze={infoCode.length}
      view={view}
    >
       <SEO title={view} />
      {this.renderFamille()}
      {this.renderAccueil()}
      {this.renderInfos()}
      {this.renderAide()}
       {/* <pre>{JSON.stringify(formatArbre(data2, '@73110@'), null, 2)}</pre> */}
    </Layout>
    )
  }
}

export default IndexPage
