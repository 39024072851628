import React from 'react';
import styled from 'styled-components'
import { useWindowEvent } from '../helpers/hooks';
import { media } from '../styles/mediaQueries';

const InputContainer = styled.div`
  margin-top: 1.4em;
  width: auto;
  height: auto;
  min-width: 30vw;
  max-width: 80vw;
  ${media.mobile`
    min-width: 90vw;
    max-width: 90vw;
  `}
  border: 1px solid #dfe1e5;
  border-radius: 24px;
  overflow: hidden;
  ${({ focused }) => focused && `
    box-shadow: 0 1px 6px 0 rgba(32,32,32,0.2);
    border-color:rgba(225,225,225,0);
  `}
  &::after {
    content: "\\1F50D";
    position: absolute;
    width: 1.5em;
    right: 0.25em;
    top: 0.25em;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10000;
  }
`

const InputStyled = styled.input`
  font-size: 1em;
  min-width: 30vw;
  max-width: 80vw;
  ${media.mobile`
    min-width: 90vw;
    max-width: 90vw;
  `}
  padding: 0.45em 2em 0.45em 1em;
  position: relative;
  border: none;
  outline: none;
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Name = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  &:first-of-type {
    border-top: 1px solid #dfe1e5;
  }
  display: block;
  padding: 0.45em 1em;
  cursor: pointer;
  &:hover {
    background: #dfe1e5;
  }
  ${({ focused }) => focused && 'background: #dfe1e5;' }
`

const normalizeWord = sentence =>
  sentence.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
const test = (regex, nom) => regex.test(normalizeWord(nom))

const Recherche = ({ individuals, onClick }) => {

  const [inputValue, setInputValue] = React.useState('')
  const [focused, setFocus] = React.useState(false)
  const [resultFocused, setResultFocus] = React.useState('')

  const RegExes = {}
  for (let word of inputValue.split(' ')) {
    if (word.length) {
      RegExes[word] = new RegExp(normalizeWord(word), "i")
    }
  }

  const personnes = inputValue.length
  ? individuals
    .filter((pers) => {
      let keep = 0;
      for (let nom of pers.recherche) {
        for (let word of Object.keys(RegExes)) {
          if (test(RegExes[word], nom)) keep += 1;
        }
      }
      return keep === Object.keys(RegExes).length;
    })
    .filter((_, ind) => ind < 10)
  : []


  React.useEffect(() => {
    if (!resultFocused.length) return;
    if (!personnes.length) {
      setResultFocus('');
      return
    }
    if (personnes.findIndex(pers => pers.id === resultFocused) < 0) {
      setResultFocus('');
      return
    }
  }, [inputValue])

  const handleKeyPress = e => {
    if (!personnes.length) {
      if (resultFocused.length) {
        setResultFocus('')
      }
      return;
    }
    switch (e.key) {
      case 'Enter':
        if (!resultFocused.length) break;
        onClick(resultFocused);
        break;
      case 'ArrowDown':
        if (!resultFocused.length) {
          setResultFocus(personnes[0].id);
          break;
        }
        if (personnes.findIndex(pers => pers.id === resultFocused) + 1 === personnes.length) {
          setResultFocus(personnes[0].id);
          break;
        }
        setResultFocus(personnes[personnes.findIndex(pers => pers.id === resultFocused) + 1].id)
        break;
      case 'ArrowUp':
        if (!resultFocused.length) break;
        if (personnes.findIndex(pers => pers.id === resultFocused) === 0) {
          setResultFocus(personnes[personnes.length - 1].id);
          break;
        }
        setResultFocus(personnes[personnes.findIndex(pers => pers.id === resultFocused) - 1].id)
        break;
      default:
        break;
    }
  }

  useWindowEvent("keydown", handleKeyPress);

  return(
    <InputContainer focused={focused} >
      <InputStyled
        placeholder="Recherchez un membre de la famille"
        type="text"
        onChange={e => setInputValue(e.target.value)}
        onFocus={e => setFocus(true)}
        onBlur={e => setFocus(false)}
        value={inputValue}
      />
      {personnes.map((pers) => (
        <Name
          key={pers.id}
          onClick={() => onClick(pers.id)}
          id={pers.id}
          focused={resultFocused === pers.id}
          dangerouslySetInnerHTML={{
            __html: pers.recherche
                      .map(nom => {
                        let formattedNom = nom;
                        for (let word of Object.keys(RegExes)) {
                          if (test(RegExes[word], nom)) formattedNom = `<b>${nom}</b>`;
                        }
                        return formattedNom;
                      })
                      .join(' ')
          }}
        />
      ))}
    </InputContainer>
    )
}

export default Recherche;
